'use client';

import { useState, useEffect, useRef } from 'react';
import { CodeBlock, Icon } from '@/components/utilities';

import {
  intelligenceDescribe,
  intelligenceDescribeOutput,
  intelligenceNudity,
  intelligenceNudityOutput,
  intelligenceObjects,
  intelligenceObjectsOutput,
  intelligenceSummarize,
  intelligenceSummarizeOutput,
  intelligenceTranscribe,
  intelligenceTranscribeOutput,
  transformsResize,
  transformsConvert,
  transformsTrim,
  transformsWatermark,
  tracksSubtitles,
  tracksChapters,
  tracksThumbnails,
  tracksSubtitlesOutput,
  tracksChaptersOutput,
  tracksThumbnailsOutput,
  tracksPoster,
  tracksPosterOutput,
  heroHosting,
  heroIntelligence,
  heroSources,
  heroTracks,
  heroAutomation,
} from '../snippets';

const heroTabs = [
  {
    id: 0,
    label: 'Hosting',
    icon: 'upload',
    code: heroHosting,
    image:
      'https://ittybit-app.ittybitcdn.com/med_49fotm33rvhWt47Q/hosting.png',
    alt: 'Social App with Image Grid, Video Recording, and Voice Messages',
    bg: 'bg-brand-blue/20',
    hover: 'bg-brand-blue/20',
    text: 'text-brand-blue',
    progress: 'bg-brand-blue/100',
  },
  {
    id: 1,
    label: 'Intelligence',
    icon: 'intelligence',
    code: heroIntelligence,
    image: 'https://ittybit-app.ittybitcdn.com/med_49g0uR24sSnA7MXR/search.png',
    alt: 'Search box and grid of image results',
    bg: 'bg-brand-magenta/20',
    hover: 'bg-brand-magenta/20',
    text: 'text-brand-magenta',
    progress: 'bg-brand-magenta/100',
  },
  {
    id: 2,
    label: 'Sources',
    icon: 'transform',
    code: heroSources,
    image:
      'https://ittybit-app.ittybitcdn.com/med_49iipk03b0U7AuPV/transforms-hero.png',
    alt: 'Different devices showing the same image',
    bg: 'bg-brand-green/20',
    hover: 'bg-brand-green/20',
    text: 'text-brand-green',
    progress: 'bg-brand-green/100',
  },
  {
    id: 3,
    label: 'Tracks',
    icon: 'tracks',
    code: heroTracks,
    image:
      'https://ittybit-app.ittybitcdn.com/med_49imrF817dpGIQrd/tracks-hero.png',
    alt: 'Video player with subtitles, chapters, and thumbnails',
    bg: 'bg-brand-orange/20',
    hover: 'bg-brand-orange/20',
    text: 'text-brand-orange',
    progress: 'bg-brand-orange/100',
  },
  {
    id: 4,
    label: 'Automation',
    icon: 'automation',
    code: heroAutomation,
    image:
      'https://ittybit-app.ittybitcdn.com/med_49igGe43Jv13wsNl/automations.png',
    alt: 'Workflow diagram with several automation tasks',
    bg: 'bg-brand-yellow/20',
    hover: 'bg-brand-yellow/20',
    text: 'text-brand-yellow',
    progress: 'bg-brand-yellow/100',
  },
];

const intelligenceTabs = [
  {
    id: 0,
    label: 'Describe',
    icon: 'description',
    code: intelligenceDescribe,
    output: intelligenceDescribeOutput,
    image:
      'https://ittybit-app.ittybitcdn.com/med_49feca91RcW4Kxmi/describe.jpg',
    alt: 'Image by Alex Knight: https://unsplash.com/photos/vaA6EQiUSo4',
    bg: 'bg-brand-magenta/20',
    hover: 'bg-brand-magenta/20',
    text: 'text-brand-magenta',
    progress: 'bg-brand-magenta/100',
  },
  {
    id: 1,
    label: 'Transcribe',
    icon: 'speech',
    code: intelligenceTranscribe,
    output: intelligenceTranscribeOutput,
    image:
      'https://ittybit-app.ittybitcdn.com/med_49feca86sfddkULE/transcribe.jpg',
    alt: 'Image by Sebastian Pandelache: https://unsplash.com/photos/dZEEhW6L6E4',
    bg: 'bg-brand-magenta/20',
    hover: 'bg-brand-magenta/20',
    text: 'text-brand-magenta',
    progress: 'bg-brand-magenta/100',
  },
  {
    id: 2,
    label: 'Labels',
    icon: 'objects',
    code: intelligenceObjects,
    output: intelligenceObjectsOutput,
    image: 'https://ittybit-app.ittybitcdn.com/med_49feca89vkxbP8ma/labels.jpg',
    alt: 'Image by Tobias Rehbein: https://unsplash.com/photos/FhnX6PDy9bA',
    bg: 'bg-brand-magenta/20',
    hover: 'bg-brand-magenta/20',
    text: 'text-brand-magenta',
    progress: 'bg-brand-magenta/100',
  },
  {
    id: 3,
    label: 'Summarize',
    icon: 'summary',
    code: intelligenceSummarize,
    output: intelligenceSummarizeOutput,
    image:
      'https://ittybit-app.ittybitcdn.com/med_49feca89M4aMDPpE/summarize.jpg',
    alt: 'Image by Teemu Paananen: https://unsplash.com/photos/bzdhc5b3Bxs',
    bg: 'bg-brand-magenta/20',
    hover: 'bg-brand-magenta/20',
    text: 'text-brand-magenta',
    progress: 'bg-brand-magenta/100',
  },
  {
    id: 4,
    label: 'NSFW',
    icon: 'nsfw',
    code: intelligenceNudity,
    output: intelligenceNudityOutput,
    image: 'https://ittybit-app.ittybitcdn.com/med_49feca912WDIV8w8/nsfw.jpg',
    alt: 'Image by Luiz Rogerio Nunes: https://unsplash.com/photos/jibqNZ61Y98',
    bg: 'bg-brand-magenta/20',
    hover: 'bg-brand-magenta/20',
    text: 'text-brand-magenta',
    progress: 'bg-brand-magenta/100',
  },
];

const transformsTabs = [
  {
    id: 0,
    label: 'Convert',
    icon: 'transform',
    code: transformsConvert,
    image1:
      'https://ittybit-app.ittybitcdn.com/med_49dnef35aMRg1f3L/transforms-1.jpg',
    image2:
      'https://ittybit-app.ittybitcdn.com/med_49dnef37Jart80bI/transforms-2.jpg',
    alt: 'Image by Sam Pearce Warrilow: https://unsplash.com/photos/wActGbRldzY',
    bg: 'bg-brand-green/20',
    hover: 'bg-brand-green/20',
    text: 'text-brand-green',
    progress: 'bg-brand-green/100',
  },
  {
    id: 1,
    label: 'Resize',
    icon: 'resize',
    code: transformsResize,
    image1:
      'https://ittybit-app.ittybitcdn.com/med_49dnef35aMRg1f3L/transforms-1.jpg',
    image2:
      'https://ittybit-app.ittybitcdn.com/med_49dnef37beTF6klK/transforms-3.jpg',
    alt: 'Image by Sam Pearce Warrilow: https://unsplash.com/photos/wActGbRldzY',
    bg: 'bg-brand-green/20',
    hover: 'bg-brand-green/20',
    text: 'text-brand-green',
    progress: 'bg-brand-green/100',
  },
  {
    id: 2,
    label: 'Trim',
    icon: 'video',
    code: transformsTrim,
    image1:
      'https://ittybit-app.ittybitcdn.com/med_49dnef35aMRg1f3L/transforms-1.jpg',
    image2:
      'https://ittybit-app.ittybitcdn.com/med_49dnef364n8IQKh2/transforms-4.jpg',
    alt: 'Image by Sam Pearce Warrilow: https://unsplash.com/photos/wActGbRldzY',
    bg: 'bg-brand-green/20',
    hover: 'bg-brand-green/20',
    text: 'text-brand-green',
    progress: 'bg-brand-green/100',
  },
  {
    id: 3,
    label: 'Watermark',
    icon: 'image',
    code: transformsWatermark,
    image1:
      'https://ittybit-app.ittybitcdn.com/med_49dnef35aMRg1f3L/transforms-1.jpg',
    image2:
      'https://ittybit-app.ittybitcdn.com/med_49dnef48Q3DzUKLF/transforms-5.jpg',
    alt: 'Image by Sam Pearce Warrilow: https://unsplash.com/photos/wActGbRldzY',
    bg: 'bg-brand-green/20',
    hover: 'bg-brand-green/20',
    text: 'text-brand-green',
    progress: 'bg-brand-green/100',
  },
];

const tracksTabs = [
  {
    id: 0,
    label: 'Poster',
    icon: 'player',
    code: tracksPoster,
    output: tracksPosterOutput,
    image:
      'https://ittybit-app.ittybitcdn.com/med_49eeOz39RbonGJ6e/tracks-0.jpg',
    alt: 'Image by Shubham Bochiwal: https://unsplash.com/photos/BYYu5nvQoUM',
    bg: 'bg-brand-orange/20',
    hover: 'bg-brand-orange/20',
    text: 'text-brand-orange',
    progress: 'bg-brand-orange/100',
  },
  {
    id: 1,
    label: 'Subtitles',
    icon: 'subtitles',
    code: tracksSubtitles,
    output: tracksSubtitlesOutput,
    image:
      'https://ittybit-app.ittybitcdn.com/med_49edu569DCwKewMb/tracks-1.jpg',
    alt: 'Image by Shubham Bochiwal: https://unsplash.com/photos/BYYu5nvQoUM',
    bg: 'bg-brand-orange/20',
    hover: 'bg-brand-orange/20',
    text: 'text-brand-orange',
    progress: 'bg-brand-orange/100',
  },
  {
    id: 2,
    label: 'Chapters',
    icon: 'chapters',
    code: tracksChapters,
    output: tracksChaptersOutput,
    image:
      'https://ittybit-app.ittybitcdn.com/med_49edu570mPNN73p2/tracks-2.jpg',
    alt: 'Image by Shubham Bochiwal: https://unsplash.com/photos/BYYu5nvQoUM',
    bg: 'bg-brand-orange/20',
    hover: 'bg-brand-orange/20',
    text: 'text-brand-orange',
    progress: 'bg-brand-orange/100',
  },
  {
    id: 3,
    label: 'Thumbnails',
    icon: 'thumbnails',
    code: tracksThumbnails,
    output: tracksThumbnailsOutput,
    image:
      'https://ittybit-app.ittybitcdn.com/med_49edu568dB1iGrGf/tracks-3.jpg',
    alt: 'Image by Shubham Bochiwal: https://unsplash.com/photos/BYYu5nvQoUM',
    bg: 'bg-brand-orange/20',
    hover: 'bg-brand-orange/20',
    text: 'text-brand-orange',
    progress: 'bg-brand-orange/100',
  },
];

export const Tabs = ({
  tabs = [],
  activeTab = tabs[0],
  setActiveTab = () => {},
  delay = 3000,
  isPaused = false,
  setIsPaused = () => {},
}) => {
  const [progress, setProgress] = useState(0);
  const intervalRef = useRef(null);
  const progressIntervalRef = useRef(null);

  const inactiveTabColors = {
    bg: `bg-gray-500/20`,
    text: `text-gray-500`,
    progress: `bg-gray-500`,
  };

  // Function to rotate to the next tab
  const rotateTab = () => {
    const currentIndex = tabs.findIndex(tab => tab.id === activeTab.id);
    const nextIndex = (currentIndex + 1) % tabs.length;
    setActiveTab(tabs[nextIndex]);
    setProgress(0);
  };

  // Set up the interval for automatic rotation
  useEffect(() => {
    if (!isPaused) {
      intervalRef.current = setInterval(rotateTab, delay);
      progressIntervalRef.current = setInterval(() => {
        setProgress(prev => Math.min(prev + 1, 100));
      }, delay / 100);
    }
    return () => {
      clearInterval(intervalRef.current);
      clearInterval(progressIntervalRef.current);
    };
  }, [isPaused, activeTab]);

  // update tab on click
  const handleTabClick = index => {
    if (tabs[index].id === activeTab.id) {
      if (isPaused) {
        // Restart rotation if clicking the same tab
        setIsPaused(false);
      } else {
        setActiveTab(tabs[index]);
        setIsPaused(true);
      }
    } else {
      setActiveTab(tabs[index]);
      setIsPaused(true);
      setProgress(50);
    }
  };

  return (
    <div className="relative flex flex-col items-stretch justify-stretch bg-black w-full h-full overflow-hidden z-10">
      <ul className="w-full flex items-stretch justify-stretch text-xs md:text-base xl:text-lg leading-6 font-medium z-20 bg-dark-50">
        {tabs?.map((tab, index) => (
          <li key={tab.id} className="grow">
            <button
              onClick={() => handleTabClick(index)}
              className="group w-full">
              <div className="flex w-full items-center justify-center gap-2 xl:gap-3 px-2 xl:px-4 pb-1 xl:pb-2 pt-2 xl:pt-3 bg-black">
                <div
                  className={`hidden md:block ${
                    tab.id === activeTab.id
                      ? activeTab.bg
                      : `${inactiveTabColors.bg} group-hover:${tab.hover}`
                  } rounded-sm p-1`}>
                  <Icon
                    icon={tab.icon}
                    className={`w-5 h-5 xl:w-6 xl:h-6 ${
                      tab.id === activeTab.id
                        ? activeTab.text
                        : `${inactiveTabColors.text} group-hover:${tab.text}`
                    }`}
                  />
                </div>
                <span>{tab.label}</span>
              </div>
              <div
                className={`w-full h-1 mt-1 ${
                  tab.id === activeTab.id
                    ? activeTab.bg
                    : `${inactiveTabColors.bg} group-hover:${tab.hover}`
                } overflow-hidden`}>
                <div
                  className={`h-full ${
                    tab.id === activeTab.id
                      ? activeTab.progress
                      : `${inactiveTabColors.progress} group-hover:${tab.progress}`
                  }`}
                  style={{
                    width: tab.id === activeTab.id ? `${progress}%` : '0%',
                    transition: 'width 60ms linear',
                  }}
                />
              </div>
            </button>
          </li>
        ))}
      </ul>
      <div className="w-full h-fulloverflow-x-hidden overflow-y-auto py-2">
        <CodeBlock
          language="javascript"
          code={activeTab.code}
          className="w-full overflow-auto"
        />
      </div>
    </div>
  );
};

export const IntelligenceTabs = ({ tabs = intelligenceTabs }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  return (
    <div className="w-full max-w-screen-2xl mx-auto flex flex-col 2xl:grid 2xl:grid-cols-12 2xl:grid-rows-6 gap-4 2xl:gap-8 px-4 md:px-8 lg:px-12 3xl:px-0">
      <div className="grid grid-cols-1 grid-rows-1 2xl:col-start-3 2xl:col-end-11 2xl:row-start-1 2xl:row-end-6 lg:px-24 2xl:px-0">
        <img
          src={activeTab.image}
          alt={activeTab.alt}
          className="col-start-1 row-start-1 w-full aspect-video rounded-lg object-cover blur-3xl opacity-80"
        />
        <img
          src={activeTab.image}
          alt={activeTab.alt}
          className="col-start-1 row-start-1 w-full aspect-video overflow-hidden rounded-lg object-cover z-10"
        />
        <button
          onClick={() => setIsPaused(!isPaused)}
          className="col-start-1 row-start-1 w-8 h-8 bg-dark-50/20 opacity-50 hover:opacity-100 rounded-sm p-1 z-30 m-1">
          <Icon
            icon={isPaused ? 'loop' : 'pause'}
            className={`w-6 h-6 text-brand-white`}
          />
        </button>
      </div>
      <div className="2xl:col-start-1 2xl:col-end-7 2xl:row-start-4 2xl:row-end-6 w-full h-[320px] overflow-hidden bg-black rounded-lg shadow-[0_4px_64px_0px] shadow-brand-purple/20">
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isPaused={isPaused}
          setIsPaused={setIsPaused}
        />
      </div>
      <div
        className="hidden 2xl:block 2xl:col-start-6 2xl:col-end-12 2xl:row-start-5 2xl:row-end-7 w-full h-[320px] overflow-x-hidden overflow-y-auto bg-black rounded-lg shadow-[0_4px_64px_0px] shadow-brand-purple/20 z-10"
        style={{
          transform: 'translateX(-48px) translateY(-48px)',
        }}>
        <CodeBlock language="json" code={activeTab.output} />
      </div>
      <img
        src="https://ittybit-app.ittybitcdn.com/med_49idRy98vXh05YXC/intelligence-animation.webp"
        width="720"
        height="610"
        className="hidden 2xl:block 2xl:col-start-9 2xl:col-end-12 2xl:row-start-2 2xl:row-end-5 w-[480px] h-auto object-contain object-right-bottom self-end pointer-events-none z-20"
        style={{
          transform: 'translateX(48px)',
        }}
      />
    </div>
  );
};

export const TransformsTabs = ({ tabs = transformsTabs }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  return (
    <div className="w-full max-w-screen-2xl mx-auto flex flex-col 2xl:grid 2xl:grid-cols-12 2xl:grid-rows-6 gap-4 px-4 md:px-8 lg:px-12 3xl:px-0">
      <div className="grid grid-cols-5 grid-rows-6 2xl:col-start-1 2xl:col-end-7 2xl:row-start-1 2xl:row-end-7 lg:px-48 2xl:px-12">
        <img
          src="https://ittybit-app.ittybitcdn.com/med_49dmDg4076s8VFxl/sam-pearce-warrilow-wActGbRldzY-unsplash.jpg"
          className="col-start-1 col-end-4 row-start-1 row-end-6 w-full h-[1080/1920] overflow-hidden rounded-lg blur-3xl opacity-80 object-cover"
        />
        <img
          src={activeTab.image1}
          alt={activeTab.alt}
          className="col-start-1 col-end-4 row-start-1 row-end-6 w-full h-[1080/1920] overflow-hidden rounded-lg object-cover z-10"
        />
        <img
          src="https://ittybit-app.ittybitcdn.com/med_49dmDg4076s8VFxl/sam-pearce-warrilow-wActGbRldzY-unsplash.jpg"
          className="col-start-3 col-end-6 row-start-2 row-end-7 w-full h-[1080/1920] overflow-hidden rounded-lg blur-3xl opacity-80 object-cover"
        />
        <img
          src={activeTab.image2}
          className="col-start-3 col-end-6 row-start-2 row-end-7 w-full h-[1080/1920] overflow-hidden rounded-lg object-cover z-20"
        />
        <button
          onClick={() => setIsPaused(!isPaused)}
          className="col-start-1 row-start-1 w-8 h-8 bg-dark-50/20 opacity-50 hover:opacity-100 rounded-sm p-1 z-30 m-1">
          <Icon
            icon={isPaused ? 'loop' : 'pause'}
            className={`w-6 h-6 text-brand-white`}
          />
        </button>
      </div>
      <div className="2xl:col-start-7 2xl:col-end-13 2xl:row-start-1 2xl:row-end-6 w-full h-[400px] 2xl:h-[640px] 2xl:mt-12 overflow-hidden bg-black rounded-lg shadow-[0_4px_64px_0px] shadow-brand-purple/20">
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isPaused={isPaused}
          setIsPaused={setIsPaused}
        />
      </div>
      <img
        src="https://ittybit-app.ittybitcdn.com/med_49hiyN62qoXp1OgY/tranform-cropped.webp"
        width="400"
        height="690"
        className="hidden 2xl:block col-start-1 col-end-3 row-start-3 row-end-6 h-[345px] w-auto object-contain object-left-bottom self-end pointer-events-none z-10"
        style={{
          transform: 'translateX(-96px) translateY(48px)',
        }}
      />
    </div>
  );
};

export const TracksTabs = ({ tabs = tracksTabs }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  return (
    <div className="w-full max-w-screen-2xl mx-auto flex flex-col-reverse 2xl:grid 2xl:grid-cols-12 2xl:grid-rows-6 gap-4 2xl:gap-y-0 2xl:gap-x-12 px-4 md:px-8 lg:px-12 3xl:px-0">
      <div className="2xl:col-start-1 2xl:col-end-6 2xl:row-start-1 2xl:row-end-4 w-full h-[360px] overflow-hidden bg-black rounded-lg shadow-[0_4px_64px_0px] shadow-brand-purple/20">
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isPaused={isPaused}
          setIsPaused={setIsPaused}
        />
      </div>
      <div
        className="hidden 2xl:block 2xl:col-start-1 2xl:col-end-6 2xl:row-start-4 2xl:row-end-7 w-full h-[360px] overflow-x-hidden overflow-y-auto bg-black rounded-lg shadow-[0_4px_64px_0px] shadow-brand-purple/20 z-10"
        style={{
          transform: 'translateY(-64px) translateX(24px)',
        }}>
        <CodeBlock language="html" code={activeTab.output} />
      </div>
      <div className="grid grid-cols-1 grid-rows-1 2xl:col-start-6 2xl:col-end-13 2xl:row-start-1 2xl:row-end-6 lg:px-24 2xl:px-0 2xl:mt-12">
        <img
          src={activeTab.image}
          alt={activeTab.alt}
          className="col-start-1 row-start-1 w-full aspect-video overflow-hidden rounded-lg object-cover blur-3xl opacity-80"
        />
        <img
          src={activeTab.image}
          alt={activeTab.alt}
          className="col-start-1 row-start-1 w-full aspect-video overflow-hidden rounded-lg object-cover z-10"
        />
        <button
          onClick={() => setIsPaused(!isPaused)}
          className="relative col-start-1 row-start-1 w-8 h-8 bg-dark-50/20 opacity-50 hover:opacity-100 rounded-sm p-1 z-30 m-1">
          <Icon
            icon={isPaused ? 'loop' : 'pause'}
            className={`w-6 h-6 text-brand-white`}
          />
        </button>
      </div>
      <img
        src="https://ittybit-app.ittybitcdn.com/med_49hiyN60bJBlCDsa/tracks.png"
        width="370"
        height="620"
        className="hidden 2xl:block col-start-11 col-end-13 row-start-4 row-end-7 h-[310px] w-auto object-contain object-right-bottom self-end pointer-events-none z-20"
        style={{
          transform: 'translateX(128px) translateY(-96px)',
        }}
      />
    </div>
  );
};

export const HeroTabs = ({ tabs = heroTabs }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  return (
    <div className="relative w-full flex flex-col gap-4 lg:gap-6 2xl:gap-12">
      <div className="grid grid-cols-1 grid-rows-1 relative lg:px-24 2xl:px-0">
        <img
          src={activeTab.image}
          alt={activeTab.alt}
          className="relative col-start-1 col-end-1 row-start-1 row-end-1 w-full aspect-video object-contain blur-3xl opacity-50"
        />
        <img
          src={activeTab.image}
          alt={activeTab.alt}
          className="relative col-start-1 col-end-1 row-start-1 row-end-1 w-full aspect-video object-contain z-10"
        />
        <button
          onClick={() => setIsPaused(!isPaused)}
          className="absolute -bottom-8 -right-4 w-8 h-8 bg-dark-50/20 opacity-50 hover:opacity-100 rounded-sm p-1 z-30 mx-4 my-4">
          <Icon
            icon={isPaused ? 'loop' : 'pause'}
            className={`w-6 h-6 text-brand-white`}
          />
        </button>
      </div>
      <div className="relative ml-0 2xl:ml-24 mr-0 2xl:-mr-8 h-[320px] overflow-hidden bg-black rounded-lg shadow-[0_4px_64px_0px] shadow-brand-purple/20">
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isPaused={isPaused}
          setIsPaused={setIsPaused}
        />
      </div>

      <img
        src="https://ittybit-app.ittybitcdn.com/med_494dKC34SRwiFuSK/bot.png"
        width="360"
        height="576"
        className="hidden 2xl:block absolute -bottom-4 -left-16 h-[288px] w-auto object-contain object-right z-30 pointer-events-none"
      />
    </div>
  );
};

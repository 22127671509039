const Testimonial = ({ logo, logoDark, company, text, avatar, name, role }) => {
  return (
    <div
      className="flex flex-col bg-white dark:bg-dark-100 rounded-sm p-4 md:p-8"
      style={{ imageRendering: 'auto' }}>
      <div className="flex space-x-3 my-4 md:my-6">
        <p className="text-base md:text-lg text-2">{text}</p>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-center space-x-2 md:space-x-3 lg:space-x-4 mt-auto">
          <img
            src={avatar}
            className="w-8 h-8 md:w-12 md:h-12 lg:w-16 lg:h-16 rounded-full object-cover"
            alt={`${name} Profile Image`}
          />
          <div>
            <h6 className="text-xs md:text-sm lg:text-base text-1 font-medium">
              {name}
            </h6>
            <h6 className="text-xs md:text-sm text-3 font-medium">{role}</h6>
          </div>
        </div>
        <div>
          <img src={logo} alt={company} className="dark:hidden h-8 md:h-12" />
          {logoDark ? (
            <img
              src={logoDark}
              alt={`${company} logo`}
              className="hidden dark:block h-8 md:h-12"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;

export function formatFilesize({ filesize = 0, decimals = 2 }) {
  if (filesize == 0) return '0 Bytes';
  const k = 1000;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let i = Math.floor(Math.log(filesize) / Math.log(k));
  return (
    parseFloat((filesize / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i]
  );
}

export function formatDate(date) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const d = new Date(date);
  const day = d.getDate();
  const monthIndex = d.getMonth();
  const month = months[monthIndex];
  const year = d.getFullYear();
  return `${month} ${day}, ${year}`;
}

export function formatDuration(duration) {
  let formattedDuration = null;
  if (duration > 3600) {
    let hours = Math.floor(duration / 3600);
    let minutes = Math.floor((duration % 3600) / 60);
    let seconds = Math.floor((duration % 3600) % 60);
    formattedDuration = `${hours}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  } else if (duration > 60) {
    let minutes = Math.floor(duration / 60);
    let seconds = Math.floor(duration % 60);
    formattedDuration = `${minutes}:${seconds.toString().padStart(2, '0')}`;
  } else if (duration) {
    let seconds = Math.floor(duration);
    formattedDuration = `0:${seconds.toString().padStart(2, '0')}`;
  }
  return formattedDuration;
}

export function initFadeInObserver() {
  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      });
    },
    {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    },
  );

  const fadeElements = document.querySelectorAll('.fade-in');
  fadeElements.forEach(el => observer.observe(el));
}

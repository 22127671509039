import Link from 'next/link';
import { Button } from '../utilities';

const CallToAction = ({ docs = true, pricing = false }) => {
  return (
    <div className="w-full flex flex-col xl:flex-row xl:justify-between justify-between items-center px-4 md:px-8 lg:px-12 3xl:px-0">
      <div className="xl:m-4 text-center xl:text-left ">
        <h2 className="text-3xl md:text-4xl 2xl:text-5xl font-bold font-title text-1 ">
          Start uploading in seconds
        </h2>
        <h2 className="text-xl md:text-2xl 2xl:text-3xl font-pixel text-brand-purple ">
          No credit card required
        </h2>
      </div>
      <div className="mt-4 md:mt-6">
        <div className="flex flex-col lg:flex-row items-center justify-center gap-4 2xl:gap-12">
          <Button href="/register" size="xl" label="Get API Key" primary />
          {docs ? <Button href="/docs" size="xl" label="View Docs" /> : null}
          {pricing ? (
            <Button href="/pricing" size="xl" label="View Pricing" />
          ) : null}
          {/* <Link
                href="/docs"
                className="text-base text-brand-500 font-medium px-5 py-3 bg-white hover:bg-brand-10 my-1.5"
              >
                View Quick Start
              </Link> */}
        </div>
      </div>
    </div>
  );
};

export default CallToAction;

const now = new Date().toISOString();

// Hero

export const heroHosting = `await fetch(\`https://your.domain/\${file.name}\`, {
  method: 'PUT',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: file
});`;

export const heroIntelligence = `fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    url: "https://your.domain/video.mp4",
    kind: "objects"
  })
});`;

export const heroSources = `fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    url: "https://your.domain/video.mp4",
    format: "cmaf",
    max: "1920px",
    layers: [
      {
        src: "https://your.domain/logo.png",
        height: 40,
        bottom: 20,
        right: 20,
      }
    ]
  })
});`;

export const heroTracks = `fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    url: "https://your.domain/video.mp4",
    kind: "chapters"
  })
});`;

export const heroAutomation = `fetch('https://api.ittybit.com/automations', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    kind: "event",
    trigger: "media.created",
    actions: [
      {
        kind: "automation",
        id: "auto_abcdefgh1234"
      }
    ]
  })
});`;

// Hosting

export const hostingUploads = `const response = await fetch('https://api.ittybit.com/uploads', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer ITTYBIT_API_KEY',
    'Ittybit-Title': '@username profile video',
    'Ittybit-Filename': 'original.mp4',
    'Ittybit-Folder': '@username/profile',
    'Ittybit-Metadata-Location': 'Los Angeles, CA',
  },
  body: file
});
`;
export const hostingIngest = `const response = await fetch('https://api.ittybit.com/media', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer ITTYBIT_API_KEY',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    src: "https://your.domain/video-to-ingest.mp4",
    title: "@username profile video",
    filename: "original.mp4",
    folder: "@username/profile",
    metadata: {
      location: "Los Angeles, CA",
    }
  })
});
`;

export const hostingStore = `const response = await fetch('https://api.ittybit.com/rules', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer ITTYBIT_API_KEY',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    kind: "ip",
    value: "156.33.241.5",
    action: "allow"
  })
});
`;

export const hostingDeliver = `// Embed
<iframe src="https://your.domain/embed/@username/original.mp4" width="720" height="1280"></iframe>

// Renders
<video width="720" height="1280" poster="https://your.domain/@username/profile/poster.jpg" controls preload="metadata">
  <source src="https://your.domain/@username/profile/av1.mp4" type="video/mp4; codecs=av1,opus" />
  <source src="https://your.domain/@username/profile/vp9.webm" type="video/webm; codecs=vp9,vorbis" />
  <source src="https://your.domain/@username/profile/original.mp4" type="video/mp4" />
  <track kind="subtitles"src="https://your.domain/@username/profile/subtitles.vtt"  srclang="en" default />
  <track kind="chapters" src="https://your.domain/@username/profile/chapters.vtt" />
  <track kind="metadata" src="https://your.domain/@username/profile/thumbnails.vtt" />
</video>`;

// Intelligence

export const intelligenceTranscribe = `const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    url: "https://your.domain/video-to-analyze.mp4",
    kind: "speech",
  })
});`;

export const intelligenceTranscribeOutput = `{
  "kind": "speech",
  "detected": true,
  "timeline": [
    {
      "start": 0,
      "end": 10,
      "text": "Hello, and welcome to UkeTube. I'm John Doe.",
      "speaker": 0,
      "confidence": 0.98
    },
    {
      "start": 10,
      "end": 20,
      "text": "And I'm Jessie Doe. Today we're going to learn how to play Viva La Vida by Coldplay.",
      "speaker": 1,
      "confidence": 0.95
    }
  ]
}`;

export const intelligenceDescribe = `const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    url: "https://your.domain/image-to-analyze.jpg",
    kind: "description",
  })
});`;

export const intelligenceDescribeOutput = `{
  "kind": "description",
  "text": "A vibrant nighttime street scene in a bustling entertainment district of Japan. The street is awash in neon lights and colorful signage, creating a dazzling, cyberpunk-like atmosphere. Large illuminated signs advertise various businesses, including Bariki, a Thai yoga massage parlor, and Hyakkendana. The buildings are densely packed, with multiple floors of establishments visible. The street level is populated with a few pedestrians, their silhouettes visible against the bright lights. The color palette is dominated by vivid pinks, blues, and teals, giving the scene a surreal, electric feel. The overall impression is one of a lively, perhaps slightly chaotic, nightlife area full of energy and activity."
}`;

export const intelligenceSummarize = `const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    url: "https://your.domain/audio-to-analyze.mp3",
    kind: "summary",
  })
});`;

export const intelligenceSummarizeOutput = `{
  "kind": "summary",
  "title": "Designing at Scale: Airbnb's Journey with Design Systems",
  "tags": ["Design systems", "Airbnb", "Product development", "Scalability", "UI/UX", "Accessibility", "React Sketchapp", "Organizational change"],
  "text": "Karri Saarinen, formerly of Airbnb, presents a compelling case for design systems as a solution to the increasing complexity of product development in the digital age. /\\n/\\nHe argues that design systems are not just about aesthetics, but are crucial tools for organizational efficiency, product consistency, and scalability./\\n/\\nSaarinen delves into Airbnb's approach to design systems, emphasizing the importance of functional components and primitives. He demonstrates how their system allows designers and engineers to work more efficiently across multiple platforms, resulting in a more cohesive user experience. The talk also touches on the process of maintaining and evolving the design system, highlighting the balance between core components and team-specific experiments./\\n/\\n### Beyond Aesthetics: The Organizational Impact/\\n/\\nWhat sets this talk apart is Saarinen's focus on the organizational aspects of implementing a design system. He stresses that success depends not just on the technical implementation, but on how well the system aligns with a company's goals and structure. This perspective elevates the conversation beyond mere design tools to a discussion of how design systems can drive broader organizational change and product strategy./\\n/\\n### Innovations and Future Directions/\\n/\\nThe presentation concludes with exciting innovations spawned by Airbnb's design system, including React Sketchapp. This open-source tool allows rendering of React components directly in Sketch, blurring the lines between design and development. Saarinen hints at the potential for such technologies to revolutionize the design process, enabling real-time language translations and responsive design previews directly within design tools."
}`;

export const intelligenceNudity = `const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    url: "https://your.domain/image-to-analyze.jpg",
    kind: "nsfw",
    threshold: 0.6,
  })
});`;

export const intelligenceNudityOutput = `{
  "kind": "nsfw",
  "detected": true,
  "items": [
    {
      "category": "nudity",
      "confidence": 0.82
    }
  ]
}`;

export const intelligenceObjects = `const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    url: "https://your.domain/video-to-analyze.mp4",
    kind: "objects",
  })
});`;

export const intelligenceObjectsOutput = `{
  "kind": "objects",
  "detected": true,
  "items": [
    {
      "label": "Nike soccer cleats",
      "confidence": 0.85,
      "box": {"x": 300, "y": 450, "w": 200, "h": 100}
    },
    {
      "label": "Uhlsport soccer ball",
      "confidence": 0.95,
      "box": {"x": 450, "y": 300, "w": 100, "h": 100}
    },
    {
      "label": "White soccer shorts",
      "confidence": 0.97,
      "box": {"x": 100, "y": 100, "w": 300, "h": 200}
    },
    {
      "label": "Red advertising board",
      "confidence": 0.97,
      "box": {"x": 0, "y": 0, "w": 800, "h": 100}
    },
    {
      "label": "White soccer socks",
      "confidence": 0.98,
      "box": {"x": 200, "y": 300, "w": 150, "h": 250}
    },
    {
      "label": "Soccer field (grass)",
      "confidence": 0.99,
      "box": {"x": 0, "y": 400, "w": 800, "h": 200}
    },
  ]
}`;
// Transforms

export const transformsConvert = `const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    url: "https://your.domain/video-to-convert.mov",
    format: "mp4",
  })
});`;

export const transformsResize = `const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    url: "https://your.domain/video-to-convert.mov",
    format: "mp4",
    width: 720,
    height: 1280,
  })
});`;

export const transformsTrim = `const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    url: "https://your.domain/video-to-convert.mov",
    format: "mp4",
    width: 720,
    height: 1280,
    end: 120,
  })
});`;

export const transformsWatermark = `const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    url: "https://your.domain/video-to-convert.mov",
    format: "mp4",
    width: 720,
    height: 1280,
    end: 120,
    layers: [
      {
        src: "https://your.domain/logo.png",
        height: 200,
        bottom: 400,
      },
      {
        text: "@watchnerd",
        bottom: 360,
      }
    ]
  })
});`;

// Tracks
export const tracksPoster = `const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    url: "https://your.domain/festivals/video.mp4",
    kind: "image",
    filename: "poster.jpg",
  })
});`;

export const tracksPosterOutput = `<video 
  width="1920" height="1080" 
  poster="https://your.domain/festivals/poster.jpg"
>
    <source src="https://your.domain/festivals/video.mp4" />
</video>`;

export const tracksSubtitles = `const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    url: "https://your.domain/festivals/video.mp4",
    kind: "subtitles",
    language: "en",
  })
});`;

export const tracksSubtitlesOutput = `<video 
  width="1920" height="1080" 
  poster="https://your.domain/festivals/poster.jpg"
>
    <source src="https://your.domain/festivals/video.mp4" />
    <track kind="subtitles" src="https://your.domain/festivals/subtitles.vtt"  srclang="en" default />
</video>`;

export const tracksChapters = `const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    url: "https://your.domain/festivals/video.mp4",
    kind: "chapters",
  })
});`;

export const tracksChaptersOutput = `<video 
  width="1920" height="1080" 
  poster="https://your.domain/festivals/poster.jpg"
>
    <source src="https://your.domain/festivals/video.mp4" />
    <track kind="subtitles" src="https://your.domain/festivals/subtitles.vtt"  srclang="en" default />
    <track kind="chapters" src="https://your.domain/festivals/chapters.vtt" />
</video>`;

export const tracksThumbnails = `const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    url: "https://your.domain/festivals/video.mp4",
    kind: "thumbnails",
  })
});`;

export const tracksThumbnailsOutput = `<video 
  width="1920" height="1080" 
  poster="https://your.domain/festivals/poster.jpg"
>
    <source src="https://your.domain/festivals/video.mp4" />
    <track kind="subtitles" src="https://your.domain/festivals/subtitles.vtt"  srclang="en" default />
    <track kind="chapters" src="https://your.domain/festivals/chapters.vtt" />
  <track kind="metadata" src="https://your.domain/festivals/thumbnails.vtt" />
</video>`;

// Automations

export const automationsTriggers = `const response = await fetch('https://api.ittybit.com/automations', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    trigger: {
      kind: "event",
      event: "media.created",
    },
    tasks: []
  })
});`;

export const automationsActions = `const response = await fetch('https://api.ittybit.com/automations', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    trigger: {
      kind: "event",
      event: "media.created",
    },
    tasks: [
      {
        label: "thumbnail",
        format: "jpeg",
        width: 100,
      }
    ]
  })
});`;

export const automationsConditionals = `const response = await fetch('https://api.ittybit.com/automations', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    trigger: {
      kind: "event",
      event: "media.created",
    },
    conditions: [
      {
        kind: "video",
      }
    ],
    tasks: [
      {
        label: "poster",
        format: "jpeg",
      }
    ]
  })
});`;

export const automationsParallel = `const response = await fetch('https://api.ittybit.com/automations', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    trigger: {
      kind: "event",
      event: "media.created",
    },
    conditions: [
      {
        kind: "video",
      }
    ],
    tasks: [
      {
        label: "poster",
        format: "jpeg",
      },
      {
        kind: "nsfw",
      },
      {
        kind: "description",
      },
    ]
  })
});`;

export const automationsSequences = `const response = await fetch('https://api.ittybit.com/automations', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    trigger: {
      kind: "event",
      event: "media.created",
    },
    tasks: [
      {
        layers: [
          {
            src: "https://your.domain/logo.png",
            width: 100,
            height: 50,
            bottom: 25,
            right: 25,
          }
        ],
        next: [
          {
            label: "poster",
            format: "jpeg",
          }
        ]
      },
    ]
  })
});`;

export const automationsNotifications = `const response = await fetch('https://api.ittybit.com/automations', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    trigger: {
      kind: "event",
      event: "media.created",
    },
    tasks: [
      {
        kind: "webhook",
        url: "https://your.domain/api/ittybit-webhooks",
      }
    ]
  })
});`;

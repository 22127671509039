'use client';

import { useEffect } from 'react';
import { Button, Icon } from '@/components/utilities';
import {
  HeroTabs,
  IntelligenceTabs,
  TracksTabs,
  TransformsTabs,
} from './components';
import { CallToAction } from '@/components/marketing';
import { initFadeInObserver } from '@/utils';

const Testimonial = ({ text, image, name, title, avatar, logo }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-start gap-4 bg-black rounded-lg shadow-[0_4px_32px_0px] shadow-brand-purple/10">
      <div className="flex flex-col items-stretch justify-stretch w-full gap-8 2xl:gap-12 p-8 2xl:p-16">
        <p className="text-xl md:text-2xl 2xl:text-3xl font-medium">
          <Icon
            icon="quote"
            className="w-6 h-6 2xl:w-8 2xl:h-8 text-brand-purple inline-block relative -top-1 md:-top-2 mr-2"
          />
          {text}
          <Icon
            icon="quote"
            className="w-6 h-6 2xl:w-8 2xl:h-8 text-brand-purple inline-block relative -top-1 md:-top-2 ml-2 rotate-180"
          />
        </p>
        <div className="flex flex-col md:flex-row items-start 2xl:items-end justify-between gap-4">
          <div className="flex items-center gap-4">
            <img
              src={avatar}
              width="48"
              height="48"
              alt={name}
              className="w-8 h-8 md:w-12 md:h-12 rounded-full object-cover object-center"
            />
            <div>
              <p className="text-sm md:text-base xl:text-lg font-medium text-1">
                {name}
              </p>
              <p className="text-sm md:text-base xl:text-lg text-3">{title}</p>
            </div>
          </div>
          <img
            src={logo}
            width="144"
            height="48"
            alt="Company Logo"
            className="hidden md:block"
          />
        </div>
      </div>
      <img
        src={image}
        width="488"
        height="400"
        alt=""
        className="hidden lg:block w-2/5 flex-shrink-0"
      />
    </div>
  );
};

export default function MarketingPage() {
  useEffect(() => {
    initFadeInObserver();
  }, []);

  // TODOS:

  // - add testimonial blocks
  // - make docs buttons link to relevant pages
  // - update footer fonts
  // - add glowing pixel background with slight parallax effect (could be svg)
  // - highlight navlink if active section
  // - add hero animation for computer vision
  // - add alternative hero section animations
  // - auto rotate heros, and add controls to navigate
  // - add pricing page with faq
  // - add looping video demos to some section tabs (v2)
  // - auto rotate between tabs if section is active
  // - enable pause and restart for video demos
  // - add bot animations when they're ready
  // - make it work on mobile
  // - add stats for social proof
  // - add logos for team backgrounds

  return (
    <div
      className="home bg-white dark:bg-dark-50 max-w-full overflow-hidden grow"
      style={{
        backgroundImage:
          'url(https://ittybit-app.ittybitcdn.com/med_4921BG02JVeYNmh1/pattern.png)',
        backgroundSize: '2400px',
        backgroundPosition: 'center',
        backgroundRepeat: 'repeat',
        textWrap: 'pretty',
      }}>
      <div className="flex flex-col gap-24 2xl:gap-48 py-16 2xl:py-32">
        <section
          id="hero"
          className="fade-in w-full max-w-screen-2xl mx-auto relative">
          <div className="grid grid-cols-1 gap-8 2xl:grid-cols-12 2xl:gap-x-20 2xl:gap-y-12 px-4 md:px-8 lg:px-12 3xl:px-0">
            <div className="col-span-1 2xl:col-span-12 text-center">
              <span className="font-pixel text-xs md:text-sm xl:text-base uppercase text-brand-purple">
                Stop messing with FFmpeg and s3 permissions
              </span>
              <h1 className="text-4xl md:text-6xl lg:text-7xl 2xl:text-8xl font-bold font-title">
                Add media features in minutes
              </h1>
            </div>

            <div className="col-span-1 2xl:col-span-7 relative">
              <HeroTabs />
            </div>

            <div className="col-span-1 2xl:col-span-5 py-0 2xl:py-16">
              <ul className="text-lg lg:text-xl 2xl:text-2xl leading-6 lg:leading-8 2xl:leading-10 text-2 space-y-3 lg:space-y-4 2xl:space-y-6">
                <li className="flex items-start justify-start gap-3 xl:gap-4 relative">
                  <Icon
                    icon="check"
                    className="w-5 h-5 2xl:w-6 2xl:h-6 flex-shrink-0 text-brand-purple block relative top-1 lg:top-2"
                  />
                  Host videos, images, and audio securely with ittybit (or use
                  your existing storage)
                </li>
                <li className="flex items-start justify-start gap-3 xl:gap-4 relative">
                  <Icon
                    icon="check"
                    className="w-5 h-5 2xl:w-6 2xl:h-6 flex-shrink-0 text-brand-purple block relative top-1 lg:top-2"
                  />
                  Convert, compress, and get rich data from your files using
                  simple API calls
                </li>
                <li className="flex items-start justify-start gap-3 xl:gap-4 relative">
                  <Icon
                    icon="check"
                    className="w-5 h-5 2xl:w-6 2xl:h-6 flex-shrink-0 text-brand-purple block relative top-1 lg:top-2"
                  />
                  Scale to millions of files and get instant broadcast-scale
                  performance
                </li>
                <li className="flex items-start justify-start gap-3 xl:gap-4 relative">
                  <Icon
                    icon="check"
                    className="w-5 h-5 2xl:w-6 2xl:h-6 flex-shrink-0 text-brand-purple block relative top-1 lg:top-2"
                  />
                  Pay less than doing everything in-house
                </li>
              </ul>
              <div className="pt-12 flex flex-col 2xl:flex-row items-center gap-4 2xl:gap-6">
                <Button
                  href="/register"
                  primary
                  label="Get API Key"
                  size="xl"
                />
                <Button href="/docs" label="View Docs" size="lg" />
              </div>
            </div>
          </div>
        </section>

        <section id="media-hosting" className="fade-in">
          <div className="text-center max-w-screen-md mx-auto px-4 md:px-8 lg:px-12 3xl:px-0">
            <h2 className="text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-bold font-title">
              Ittybit make media APIs you'll actually enjoy using
            </h2>
            <p className="text-base md:text-lg xl:text-xl text-3 mt-2 xl:mt-4">
              Use them independently to quickly add media hosting, intelligence,
              or transformations to your product. Or combine them together to
              build powerful media automations that scale to millions of
              operations.
            </p>
          </div>
          <div className="max-w-screen-2xl mx-auto px-4 md:px-8 lg:px-12 3xl:px-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 gap-2 xl:gap-4 2xl:gap-6 mt-4 xl:mt-8">
            <a
              href="#media-hosting"
              className="group bg-dark-50 rounded-lg overflow-hidden hover:shadow-[0_4px_64px_0px] hover:shadow-brand-purple/20">
              <div className="bg-brand-blue/10 group-hover:bg-brand-blue/20 p-4 xl:p-8 space-y-1 xl:space-y-2 text-center flex flex-col h-full items-center">
                <Icon
                  icon="upload"
                  className="w-10 h-10 2xl:w-12 2xl:h-12 text-brand-blue"
                />
                <h3 className="text-xl lg:text-2xl 2xl:text-3xl font-bold font-title">
                  Hosting
                </h3>
                <p className="text-sm md:text-base xl:text-lg text-2">
                  Upload, store, and deliver video, image, and audio files
                </p>
              </div>
            </a>
            <a
              href="#media-intelligence"
              className="group bg-dark-50 rounded-lg overflow-hidden hover:shadow-[0_4px_64px_0px] hover:shadow-brand-purple/20">
              <div className="bg-brand-magenta/10 group-hover:bg-brand-magenta/20 p-4 xl:p-8 space-y-1 xl:space-y-2 text-center flex flex-col h-full items-center">
                <Icon
                  icon="intelligence"
                  className="w-10 h-10 2xl:w-12 2xl:h-12 text-brand-magenta"
                />
                <h3 className="text-xl lg:text-2xl 2xl:text-3xl font-bold font-title">
                  Intelligence
                </h3>
                <p className="text-sm md:text-base xl:text-lg text-2">
                  Extract rich insights from your media files
                </p>
              </div>
            </a>
            <a
              href="#media-transforms"
              className="group bg-dark-50 rounded-lg overflow-hidden hover:shadow-[0_4px_64px_0px] hover:shadow-brand-purple/20">
              <div className="bg-brand-green/10 group-hover:bg-brand-green/20 p-4 xl:p-8 space-y-1 xl:space-y-2 text-center flex flex-col h-full items-center">
                <Icon
                  icon="transform"
                  className="w-10 h-10 2xl:w-12 2xl:h-12 text-brand-green"
                />
                <h3 className="text-xl lg:text-2xl 2xl:text-3xl font-bold font-title">
                  Sources
                </h3>
                <p className="text-sm md:text-base xl:text-lg text-2">
                  Convert, compress, and transform your media
                </p>
              </div>
            </a>
            <a
              href="#media-tracks"
              className="group bg-dark-50 rounded-lg overflow-hidden hover:shadow-[0_4px_64px_0px] hover:shadow-brand-purple/20">
              <div className="bg-brand-orange/10 group-hover:bg-brand-orange/20 p-4 xl:p-8 space-y-1 xl:space-y-2 text-center flex flex-col h-full items-center">
                <Icon
                  icon="tracks"
                  className="w-10 h-10 2xl:w-12 2xl:h-12 text-brand-orange"
                />
                <h3 className="text-xl lg:text-2xl 2xl:text-3xl font-bold font-title">
                  Tracks
                </h3>
                <p className="text-sm md:text-base xl:text-lg text-2">
                  Enhance your media player's user experience
                </p>
              </div>
            </a>
            <a
              href="#media-automation"
              className="group bg-dark-50 rounded-lg overflow-hidden hover:shadow-[0_4px_64px_0px] hover:shadow-brand-purple/20">
              <div className="bg-brand-yellow/10 group-hover:bg-brand-yellow/20 p-4 xl:p-8 space-y-1 xl:space-y-2 text-center flex flex-col h-full items-center">
                <Icon
                  icon="automation"
                  className="w-10 h-10 2xl:w-12 2xl:h-12 text-brand-yellow"
                />
                <h3 className="text-xl lg:text-2xl 2xl:text-3xl font-bold font-title">
                  Automation
                </h3>
                <p className="text-sm md:text-base xl:text-lg text-2">
                  Handle millions of files with powerful media workflows
                </p>
              </div>
            </a>
          </div>
        </section>

        <section className="2xl:-mt-24 fade-in">
          <div className="max-w-screen-xl mx-auto px-4 md:px-8 lg:px-12 3xl:px-0">
            <Testimonial
              text="Ittybit lets us handle user-generated content without worrying about weird formats, malicious files, or inappropriate material"
              name="Becky Fatemi"
              title="Founder, BlackBook"
              avatar="https://ittybit-app.ittybitcdn.com/med_49ilDo65xWfkvXld/becky.jpg"
              logo="https://ittybit-app.ittybitcdn.com/med_49ilDo66T9UJ8VKl/blackbook-logo.png"
              image="https://ittybit-app.ittybitcdn.com/med_49ilxt34oRFrMyHB/blackbook-image.png"
            />
          </div>
        </section>

        <section id="how-it-works-1" className="fade-in">
          <div className="text-center max-w-screen-md mx-auto px-4 md:px-8 lg:px-12 3xl:px-0">
            <span className="font-pixel text-xs md:text-sm xl:text-base uppercase text-brand-purple">
              How It Works
            </span>
            <h2 className="text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-bold font-title">
              1. Add Media
            </h2>
            <p className="text-base md:text-xl xl:text-2xl leading-6 2xl:leading-10 text-2 mt-1 2xl:mt-4">
              Upload video, image, and audio files; ingest your existing media;
              or keep using your existing storage
            </p>
          </div>
        </section>

        <section id="media-hosting" className="fade-in">
          <div className="flex flex-col 2xl:flex-row items-start 2xl:items-end justify-start 2xl:justify-between gap-4 2xl:gap-12 max-w-screen-xl mx-auto px-4 md:px-8 lg:px-12 3xl:px-0">
            <div className="flex-shrink-0 w-full 2xl:w-2/3 text-center 2xl:text-left">
              <span className="font-pixel text-xs md:text-sm xl:text-base uppercase text-brand-blue">
                <del className="text-5">
                  Separate Storage, CDN, and Player bills
                </del>
                <br />A single API for media hosting
              </span>
              <h3 className="text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-bold font-title">
                Easy uploads, secure storage, and rapid global delivery
              </h3>
            </div>
            <p className="text-center 2xl:text-right text-base md:text-lg xl:text-xl 2xl:text-lg text-3">
              <code className="inline-block uppercase px-1 xl:px-2 2xl:px-1 py-1 font-pixel font-normal text-sm md:text-base xl:text-lg 2xl:text-base leading-4 bg-black text-brand-blue">
                hosting
              </code>{' '}
              lets you support video, image, and audio files in your product in
              minutes, scale to millions of media items robustly, and save 50%
              on costs vs AWS
            </p>
          </div>

          <div className="relative max-w-screen-2xl mx-auto flex flex-col 2xl:grid 2xl:grid-cols-12 gap-4 2xl:gap-8 mt-8 2xl:mt-16 px-4 md:px-8 lg:px-12 3xl:px-0">
            <div className="col-span-1 2xl:col-span-8 flex flex-col-reverse md:flex-row-reverse 2xl:flex-row items-stretch justify-start bg-black rounded-lg overflow-hidden 2xl:min-h-[480px] shadow-[0_4px_32px_0px] shadow-brand-purple/10">
              <img
                src="https://ittybit-app.ittybitcdn.com/med_49egCE55EgqmqK7W/uploads.jpg"
                width="480"
                height="480"
                alt="Uploads UI"
                className="w-full h-auto md:h-full md:w-1/2 lg:w-2/5 2xl:w-1/2 object-cover"
              />
              <div className="flex flex-col items-start justify-start flex-shrink-0 w-full md:w-1/2 lg:w-3/5 2xl:w-1/2 p-6 md:p-8 xl:p-12 space-y-3 2xl:space-y-4">
                <div className="flex items-center gap-3 xl:gap-4">
                  <div
                    className={`bg-brand-blue/20 group-hover:bg-brand-blue/20 rounded-md p-1 xl:p-2`}>
                    <Icon
                      icon="upload"
                      className={`w-6 h-6 xl:w-8 xl:h-8 text-brand-blue`}
                    />
                  </div>
                  <span className="text-xl lg:text-2xl 2xl:text-3xl font-medium">
                    Upload Files
                  </span>
                </div>
                <p className="text-base md:text-lg xl:text-xl text-2">
                  Upload any media file securely from your server (with your API
                  key) or directly from your client apps (with signed upload
                  urls).
                </p>
              </div>
            </div>
            <div className="relative col-span-1 2xl:col-span-4 bg-black rounded-lg 2xl:min-h-[480px] shadow-[0_4px_32px_0px] shadow-brand-purple/10">
              <div className="flex flex-col items-start justify-start flex-shrink-0 w-full p-6 md:p-8 xl:p-12 space-y-3 2xl:space-y-4">
                <div className="flex items-center gap-3 xl:gap-4">
                  <div
                    className={`bg-brand-blue/20 group-hover:bg-brand-blue/20 rounded-md p-1 xl:p-2`}>
                    <Icon
                      icon="ingest"
                      className={`w-6 h-6 xl:w-8 xl:h-8 text-brand-blue`}
                    />
                  </div>
                  <span className="text-xl lg:text-2xl 2xl:text-3xl font-medium">
                    Ingest Media
                  </span>
                </div>
                <p className="text-base md:text-lg xl:text-xl text-2">
                  Pull individual media from your existing storage as you need
                  them, or import your entire library with just a few lines of
                  code.
                </p>
              </div>
            </div>
            <div className="relative col-span-1 2xl:col-span-4 bg-black rounded-lg 2xl:min-h-[480px] shadow-[0_4px_32px_0px] shadow-brand-purple/10">
              <div className="flex flex-col items-start justify-start flex-shrink-0 w-full p-6 md:p-8 xl:p-12 space-y-3 2xl:space-y-4">
                <div className="flex items-center gap-3 xl:gap-4">
                  <div
                    className={`bg-brand-blue/20 group-hover:bg-brand-blue/20 rounded-md p-1 xl:p-2`}>
                    <Icon
                      icon="security"
                      className={`w-6 h-6 xl:w-8 xl:h-8 text-brand-blue`}
                    />
                  </div>
                  <span className="text-xl lg:text-2xl 2xl:text-3xl font-medium">
                    Store Securely
                  </span>
                </div>
                <p className="text-base md:text-lg xl:text-xl text-2">
                  Files are encrypted at rest, and only served over HTTPS in
                  transit. Add custom security rules to retain full control over
                  when and who can access your media.
                </p>
              </div>
            </div>
            <div className="col-span-1 2xl:col-span-8 flex flex-col md:flex-row items-stretch justify-start bg-black rounded-lg overflow-hidden 2xl:min-h-[480px] shadow-[0_4px_32px_0px] shadow-brand-purple/10">
              <div className="flex flex-col items-start justify-start flex-shrink-0 w-full md:w-1/2 lg:w-3/5 2xl:w-1/2 p-6 md:p-8 xl:p-12 space-y-3 2xl:space-y-4">
                <div className="flex items-center gap-3 xl:gap-4">
                  <div
                    className={`bg-brand-blue/20 group-hover:bg-brand-blue/20 rounded-md p-1 xl:p-2`}>
                    <Icon
                      icon="cdn"
                      className={`w-6 h-6 xl:w-8 xl:h-8 text-brand-blue`}
                    />
                  </div>
                  <span className="text-xl lg:text-2xl 2xl:text-3xl font-medium">
                    Deliver Globally
                  </span>
                </div>
                <p className="text-base md:text-lg xl:text-xl text-2">
                  Embed and stream your media files globally with low latency.
                  Our multi-CDN approach guarantees performance and reliability
                  across continentsat a single affordable price.
                </p>
              </div>
              <img
                src="https://ittybit-app.ittybitcdn.com/med_49eksM463CRqqsus/cdn.jpg"
                width="480"
                height="480"
                alt="Delivery Network Locations (Image from https://unsplash.com/photos/Xu4Pz7GI9JY)"
                className="w-full h-auto md:h-full md:w-1/2 lg:w-2/5 2xl:w-1/2 object-cover"
              />
            </div>
            <img
              src="https://ittybit-app.ittybitcdn.com/med_49hiyN576pB3wcNb/drone.webp"
              width="480"
              height="800"
              className="hidden 2xl:block h-[400px] w-auto object-contain object-left absolute top-8 -left-16 3xl:-left-32 z-30 pointer-events-none"
            />
            <img
              src="https://ittybit-app.ittybitcdn.com/med_4920EH72U3gvP61V/delivery.png"
              width="650"
              height="530"
              className="hidden 2xl:block w-[325px] h-auto object-contain object-right absolute top-1/2 -right-16 3xl:-right-32 z-30 pointer-events-none"
            />
          </div>

          <div className="relative mt-12 mx-auto flex flex-col 2xl:flex-row items-center justify-center gap-4 2xl:gap-6 z-20">
            <Button href="/register" primary label="Get API Key" size="xl" />
            <Button href="/docs" label="View Docs" size="lg" />
          </div>
        </section>

        <section id="how-it-works-2" className="fade-in">
          <div className="text-center max-w-screen-md mx-auto px-4 md:px-8 lg:px-12 3xl:px-0">
            <span className="font-pixel text-xs md:text-sm xl:text-base uppercase text-brand-purple">
              How It Works
            </span>
            <h2 className="text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-bold font-title">
              2. Create Tasks
            </h2>
            <p className="text-base md:text-xl xl:text-2xl leading-6 2xl:leading-10 text-2 mt-1 2xl:mt-4">
              Perform powerful media tasks with a single declarative API
            </p>
          </div>
        </section>

        <section id="media-intelligence" className="fade-in">
          <div className="flex flex-col 2xl:flex-row items-start 2xl:items-end justify-start 2xl:justify-between gap-4 2xl:gap-12 max-w-screen-xl mx-auto px-4 md:px-8 lg:px-12 3xl:px-0">
            <div className="flex-shrink-0 w-full 2xl:w-2/3 text-center 2xl:text-left">
              <span className="font-pixel text-xs md:text-sm xl:text-base uppercase text-brand-magenta">
                <del className="text-5">Dumb Bytes or Manual Tagging</del>
                <br />
                Smart Data and Instant Indexing
              </span>
              <h3 className="text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-bold font-title">
                Make your video, image, and audio files more useful
              </h3>
            </div>
            <p className="text-center 2xl:text-right text-base md:text-lg xl:text-xl 2xl:text-lg text-3">
              Use{' '}
              <code className="inline-block uppercase px-1 xl:px-2 2xl:px-1 py-1 font-pixel font-normal text-sm md:text-base xl:text-lg 2xl:text-base leading-4 bg-black text-brand-magenta">
                intelligence
              </code>{' '}
              to extract rich insights from files. Get best-in-class models, and
              scale without thinking about servers or GPUs.
            </p>
          </div>

          <div className="mt-6 2xl:mt-12 mx-auto">
            <IntelligenceTabs />
          </div>
          <div className="relative mt-12 mx-auto flex flex-col 2xl:flex-row items-center justify-center gap-4 2xl:gap-6 z-20">
            <Button href="/register" primary label="Get API Key" size="xl" />
            <Button href="/docs" label="View Docs" size="lg" />
          </div>
        </section>

        <section id="media-transforms" className="fade-in">
          <div className="flex flex-col 2xl:flex-row items-start 2xl:items-end justify-start 2xl:justify-between gap-4 2xl:gap-12 max-w-screen-xl mx-auto px-4 md:px-8 lg:px-12 3xl:px-0">
            <div className="flex-shrink-0 w-full 2xl:w-2/3 text-center 2xl:text-left">
              <span className="font-pixel text-xs md:text-sm xl:text-base uppercase text-brand-green">
                <del className="text-5">
                  Complex Commands and StackOverflow Copypasta
                </del>
                <br />
                Simple Props and a Declarative API
              </span>
              <h3 className="text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-bold font-title">
                Convert, compress, and transform your media files
              </h3>
            </div>
            <p className="text-center 2xl:text-right text-base md:text-lg xl:text-xl 2xl:text-lg text-3">
              Create{' '}
              <code className="inline-block uppercase px-1 xl:px-2 2xl:px-1 py-1 font-pixel font-normal text-sm md:text-base xl:text-lg 2xl:text-base leading-4 bg-black text-brand-green">
                Sources
              </code>{' '}
              to normalise files for different devices, shrink files to cut
              buffering and bandwidth costs, or add your own branding to
              uploaded media
            </p>
          </div>

          <div className="mt-12 mx-auto">
            <TransformsTabs />
          </div>
          <div className="relative mt-12 mx-auto flex flex-col 2xl:flex-row items-center justify-center gap-4 2xl:gap-6 z-20">
            <Button href="/register" primary label="Get API Key" size="xl" />
            <Button href="/docs" label="View Docs" size="lg" />
          </div>
        </section>

        <section id="media-tracks" className="fade-in">
          <div className="flex flex-col 2xl:flex-row items-start 2xl:items-end justify-start 2xl:justify-between gap-4 2xl:gap-12 max-w-screen-xl mx-auto px-4 md:px-8 lg:px-12 3xl:px-0">
            <div className="flex-shrink-0 w-full 2xl:w-2/3 text-center 2xl:text-left">
              <span className="font-pixel text-xs md:text-sm xl:text-base uppercase text-brand-orange">
                <del className="text-5">Frustrated users</del>
                <br />
                Engaging embeds
              </span>
              <h3 className="text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-bold font-title">
                Enhance your media player's user experience
              </h3>
            </div>
            <p className="text-center 2xl:text-right text-base md:text-lg xl:text-xl 2xl:text-lg text-3">
              Add{' '}
              <code className="inline-block uppercase px-1 xl:px-2 2xl:px-1 py-1 font-pixel font-normal text-sm md:text-base xl:text-lg 2xl:text-base leading-4 bg-black text-brand-orange">
                Tracks
              </code>{' '}
              to enhance your media player's user experience with essential
              accessibility features (search engines love them too)
            </p>
          </div>

          <div className="relative mt-12 mx-auto">
            <TracksTabs />
          </div>
          <div className="relative mt-12 mx-auto flex flex-col 2xl:flex-row items-center justify-center gap-4 2xl:gap-6 z-20">
            <Button href="/register" primary label="Get API Key" size="xl" />
            <Button href="/docs" label="View Docs" size="lg" />
          </div>
        </section>

        <section id="how-it-works-3" className="fade-in">
          <div className="text-center max-w-screen-md mx-auto px-4 md:px-8 lg:px-12 3xl:px-0">
            <span className="font-pixel text-xs md:text-sm xl:text-base uppercase text-brand-purple">
              How It Works
            </span>
            <h2 className="text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-bold font-title">
              3. Automate
            </h2>
            <p className="text-base md:text-xl xl:text-2xl leading-6 2xl:leading-10 text-2 mt-1 2xl:mt-4">
              Handle millions of files without any queues, cron jobs, or retry
              handlers for you to babysit
            </p>
          </div>
        </section>

        <section id="media-automation" className="fade-in">
          <div className="flex flex-col 2xl:flex-row items-start 2xl:items-end justify-start 2xl:justify-between gap-4 2xl:gap-12 max-w-screen-xl mx-auto px-4 md:px-8 lg:px-12 3xl:px-0">
            <div className="flex-shrink-0 w-full 2xl:w-2/3 text-center 2xl:text-left">
              <span className="font-pixel text-xs md:text-sm xl:text-base uppercase text-brand-yellow">
                <del className="text-5">
                  Provisioning, Timeouts, and Endless Headaches
                </del>
                <br />
                Effortless Scaling Handled For You
              </span>
              <h3 className="text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-bold font-title">
                Build robust media workflows in just a few lines of code
              </h3>
            </div>
            <p className="text-center 2xl:text-right text-base md:text-lg xl:text-xl 2xl:text-lg text-3">
              <code className="inline-block uppercase px-1 xl:px-2 2xl:px-1 py-1 font-pixel font-normal text-sm md:text-base xl:text-lg 2xl:text-base leading-4 bg-black text-brand-yellow">
                Automations
              </code>{' '}
              let you combine{' '}
              <code className="inline-block uppercase px-1 xl:px-2 2xl:px-1 py-1 font-pixel font-normal text-sm md:text-base xl:text-lg 2xl:text-base leading-4 bg-black text-brand-magenta">
                Intelligence
              </code>
              ,{' '}
              <code className="inline-block uppercase px-1 xl:px-2 2xl:px-1 py-1 font-pixel font-normal text-sm md:text-base xl:text-lg 2xl:text-base leading-4 bg-black text-brand-green">
                Sources
              </code>
              , and{' '}
              <code className="inline-block uppercase px-1 xl:px-2 2xl:px-1 py-1 font-pixel font-normal text-sm md:text-base xl:text-lg 2xl:text-base leading-4 bg-black text-brand-blue">
                Hosting
              </code>{' '}
              tasks into powerful workflows that scale up automatically
            </p>
          </div>

          <div className="relative max-w-screen-2xl mx-auto flex flex-col 2xl:grid 2xl:grid-cols-12 gap-4 2xl:gap-8 mt-8 2xl:mt-16 px-4 md:px-8 lg:px-12 3xl:px-0">
            <div className="relative col-span-1 2xl:col-span-4 bg-black rounded-lg 2xl:min-h-[480px] shadow-[0_4px_32px_0px] shadow-brand-purple/10">
              <div className="flex flex-col items-start justify-start flex-shrink-0 w-full p-6 md:p-8 xl:p-12 space-y-3 2xl:space-y-4">
                <div className="flex items-center gap-3 xl:gap-4">
                  <div
                    className={`bg-brand-yellow/20 group-hover:bg-brand-yellow/20 rounded-md p-1 xl:p-2`}>
                    <Icon
                      icon="quickstart"
                      className={`w-6 h-6 xl:w-8 xl:h-8 text-brand-yellow`}
                    />
                  </div>
                  <span className="text-xl lg:text-2xl 2xl:text-3xl font-medium">
                    Setup Triggers
                  </span>
                </div>
                <p className="text-base md:text-lg xl:text-xl text-2">
                  Every action on the ittybit API creates an event e.g.{' '}
                  <span className="inline-block font-code bg-black px-1 py-0.5">
                    file.created
                  </span>
                  . Pick and choose which events to listen for, and use them to
                  trigger automations.
                </p>
              </div>
            </div>
            <div className="col-span-1 2xl:col-span-8 flex flex-col md:flex-row items-stretch justify-start bg-black rounded-lg overflow-hidden 2xl:min-h-[480px] shadow-[0_4px_32px_0px] shadow-brand-purple/10">
              <div className="flex flex-col items-start justify-start md:flex-shrink-0 w-full md:w-1/2 lg:w-3/5 2xl:w-1/2 p-6 md:p-8 xl:p-12 space-y-3 2xl:space-y-4">
                <div className="flex items-center gap-3 xl:gap-4">
                  <div
                    className={`bg-brand-yellow/20 group-hover:bg-brand-yellow/20 rounded-md p-1 xl:p-2`}>
                    <Icon
                      icon="workflows"
                      className={`w-6 h-6 xl:w-8 xl:h-8 text-brand-yellow`}
                    />
                  </div>
                  <span className="text-xl lg:text-2xl 2xl:text-3xl font-medium">
                    Run Workflows
                  </span>
                </div>
                <p className="text-base md:text-lg xl:text-xl text-2">
                  Create powerful workflows once (via API or visual builder)
                  then run them again-and-again. Ittybit handles concurrency,
                  waiting for long-running tasks, and automatic retries for you.
                </p>
              </div>
              <img
                src="https://ittybit-app.ittybitcdn.com/med_49embl51TMHcLVkX/workflow.jpg"
                width="480"
                height="480"
                alt="Workflows UI"
                className="h-full w-full md:w-1/2 lg:w-2/5 2xl:w-1/2 md:h-auto object-cover"
              />
            </div>
            <div className="col-span-1 2xl:col-span-8 flex flex-col-reverse md:flex-row-reverse 2xl:flex-row items-stretch justify-start bg-black rounded-lg overflow-hidden 2xl:min-h-[480px] shadow-[0_4px_32px_0px] shadow-brand-purple/10">
              <img
                src="https://ittybit-app.ittybitcdn.com/med_49emh662KBQfpmSX/conditions.jpg"
                width="480"
                height="480"
                alt="Conditions Code Sample"
                className="h-full w-full md:w-1/2 lg:w-2/5 2xl:w-1/2 md:h-auto object-cover"
              />
              <div className="flex flex-col items-start justify-start md:flex-shrink-0 w-full md:w-1/2 lg:w-3/5 2xl:w-1/2 p-6 md:p-8 xl:p-12 space-y-3 2xl:space-y-4">
                <div className="flex items-center gap-3 xl:gap-4">
                  <div
                    className={`bg-brand-yellow/20 group-hover:bg-brand-yellow/20 rounded-md p-1 xl:p-2`}>
                    <Icon
                      icon="logs"
                      className={`w-6 h-6 xl:w-8 xl:h-8 text-brand-yellow`}
                    />
                  </div>
                  <span className="text-xl lg:text-2xl 2xl:text-3xl font-medium">
                    Configure Rules
                  </span>
                </div>
                <p className="text-base md:text-lg xl:text-xl text-2">
                  Add conditional logic to your workflows to control when
                  actions are run, avoid duplicate files, and save on costs.
                </p>
              </div>
            </div>
            <div className="relative col-span-1 2xl:col-span-4 bg-black rounded-lg 2xl:min-h-[480px] shadow-[0_4px_32px_0px] shadow-brand-purple/10">
              <div className="flex flex-col items-start justify-start flex-shrink-0 w-full p-6 md:p-8 xl:p-12 space-y-3 2xl:space-y-4">
                <div className="flex items-center gap-3 xl:gap-4">
                  <div
                    className={`bg-brand-yellow/20 group-hover:bg-brand-yellow/20 rounded-md p-1 xl:p-2`}>
                    <Icon
                      icon="sequences"
                      className={`w-6 h-6 xl:w-8 xl:h-8 text-brand-yellow`}
                    />
                  </div>
                  <span className="text-xl lg:text-2xl 2xl:text-3xl font-medium">
                    Scale Automatically
                  </span>
                </div>
                <p className="text-base md:text-lg xl:text-xl text-2">
                  Ittybit automatically scales your workflows down when you
                  don't need them and up again when you do, without you having
                  to think about it. Broadcast-scale infrastructure can handle
                  as many files as you need as you grow.
                </p>
              </div>
            </div>

            <img
              src="https://ittybit-app.ittybitcdn.com/med_4920EH704NvCqU2r/automation.png"
              width="560"
              height="570"
              className="hidden 2xl:block h-[285px] w-auto object-contain object-right absolute -left-16 3xl:-left-32 -bottom-6 z-30 pointer-events-none"
              style={{
                transform: 'scaleX(-1)',
              }}
            />
          </div>

          <div className="relative mt-12 mx-auto flex flex-col 2xl:flex-row items-center justify-center gap-4 2xl:gap-6 z-20">
            <Button href="/register" primary label="Get API Key" size="xl" />
            <Button href="/docs" label="View Docs" size="lg" />
          </div>
        </section>

        <section id="how-it-works-4" className="fade-in">
          <div className="text-center max-w-screen-md mx-auto px-4 md:px-8 lg:px-12 3xl:px-0">
            <span className="font-pixel text-xs md:text-sm xl:text-base uppercase text-brand-purple">
              How It Works
            </span>
            <h2 className="text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-bold font-title">
              4. Make It Better
            </h2>
            <p className="text-base md:text-xl xl:text-2xl leading-6 2xl:leading-10 text-2 mt-1 2xl:mt-4">
              We're a small team of passionate engineers who can work with you
              to build the exact features you need quickly
            </p>
          </div>
        </section>

        <section id="about" className="fade-in">
          <div className="text-left max-w-screen-lg mx-auto px-4 md:px-8 lg:px-12 3xl:px-0">
            <span className="font-pixel text-xs md:text-sm xl:text-base uppercase text-brand-pink">
              Media APIs <del className="text-5">for</del> by Developers
            </span>
            <h3 className="text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-bold font-title">
              We are building the APIs we wish had existed for our previous
              products and companies
            </h3>
            <div className="relative pt-8 text-base md:text-xl xl:text-2xl text-2 space-y-3 xl:space-y-4">
              <p>Media uploads are table stakes for modern apps.</p>
              <p>
                Video has become even more important during covid and the years
                after.
              </p>
              <p>
                And AI has suddenly made every pixel and sound wave much more
                valuable.
              </p>
              <h3 className="text-xl md:text-2xl xl:text-3xl font-bold font-title pt-8">
                Shitty choices
              </h3>
              <p>
                But developers still have to choose between two terrible
                options:
                <ul>
                  <li className="flex items-start justify-start gap-3 mt-2 relative">
                    <Icon
                      icon="cross"
                      className="w-6 h-6 flex-shrink-0 text-brand-purple block relative top-1"
                    />
                    Paying a *massive* premium for managed services (that don't
                    even offer the flexibility they really need).
                  </li>
                  <li className="flex items-start justify-start gap-3 mt-2 relative">
                    <Icon
                      icon="cross"
                      className="w-6 h-6 flex-shrink-0 text-brand-purple block relative top-1"
                    />
                    Building their own Rube Goldberg machines of AWS primitives,
                    third-party APIs, and glue-code – all reinventing the wheel
                    every time instead of focusing on their product's uniquely
                    valuable features!
                  </li>
                </ul>
              </p>
              <p>
                We faced the same issues in our previous company, and we spent
                years battling weird file format edge cases, figuring out how to
                scale very spiky workloads, and building defences against
                malicious uploads before eventually getting to a successful
                exit.
              </p>
              <div className="relative mt-4 mx-auto py-4 lg:py-8 px-4 md:px-16 lg:px-24">
                <a href="https://x.com/Pinboard/status/761656824202276864">
                  <img
                    src="https://ittybit-app.ittybitcdn.com/med_493fzz3328tVPTNi/tweet.png"
                    width="1186"
                    height="440"
                    className="w-full h-auto"
                    alt="Pinboard @pinboard said 'The Programmers Credo: we do these things not because they are easy, but because we thought they were going to be easy' on Twitter 1:15PM - Aug 5, 2016"
                  />
                </a>
              </div>
              <h3 className="text-xl md:text-2xl xl:text-3xl font-bold font-title pt-8">
                Devtool Era
              </h3>
              <p>
                In 2023 we started building something new, and – surprise – we
                needed uploads.
              </p>
              <p>
                We figured the situation would have changed drastically since
                2020. So we spoke to hundreds of developers to find out what
                they were using for their media files. This is what we heard:
                <ul>
                  <li className="flex items-start justify-start gap-3 mt-2 relative">
                    <Icon
                      icon="quote"
                      className="w-6 h-6 flex-shrink-0 text-brand-purple block relative top-1"
                    />
                    "We need to customise the workflow more than [managed
                    provider] ... our only option was to roll our own"
                  </li>
                  <li className="flex items-start justify-start gap-3 mt-2 relative">
                    <Icon
                      icon="quote"
                      className="w-6 h-6 flex-shrink-0 text-brand-purple block relative top-1"
                    />
                    "It simply isn't viable to add more video features at the
                    prices that [managed image hosting provider] charge"
                  </li>
                  <li className="flex items-start justify-start gap-3 mt-2 relative">
                    <Icon
                      icon="quote"
                      className="w-6 h-6 flex-shrink-0 text-brand-purple block relative top-1"
                    />
                    "State of the art models update every month but we're still
                    waiting for [video provider] to add basic features"
                  </li>
                  <li className="flex items-start justify-start gap-3 mt-2 relative">
                    <Icon
                      icon="quote"
                      className="w-6 h-6 flex-shrink-0 text-brand-purple block relative top-1"
                    />
                    "[Provider] are solid for video and [provider] are great for
                    images. But it's annoying to have two bills and having to
                    send video files one way and image files another is a bit
                    clunky."
                  </li>
                  <li className="flex items-start justify-start gap-3 mt-2 relative">
                    <Icon
                      icon="quote"
                      className="w-6 h-6 flex-shrink-0 text-brand-purple block relative top-1"
                    />
                    "Egress from [object storage provider] is free so using that
                    for now. Hopefully we never get big enough for their sales
                    team to call us lol"
                  </li>
                </ul>
              </p>
              <p>
                If anything, whisper and multimodal models and LLMs had made
                things even more complicated for developers.
              </p>
              <div className="relative mt-4 mx-auto py-4 lg:py-8 px-4 md:px-16 lg:px-24">
                <img
                  src="https://ittybit-app.ittybitcdn.com/med_4949P513IOEMkubl/thanos.jpg"
                  width="500"
                  height="247"
                  className="w-full h-auto"
                  alt="Thanos says 'Fine. I'll do it myself'"
                />
              </div>
              <p>
                So... we dropped our other project and dedicated ourselves to
                building the canonical set of media APIs that every developer
                needs in their stack.
              </p>
              <h3 className="text-xl md:text-2xl xl:text-3xl font-bold font-title pt-8">
                Why ittybit?
              </h3>
              <p>
                Our goals are:
                <ul>
                  <li className="flex items-start justify-start gap-3 mt-2 relative">
                    <Icon
                      icon="check"
                      className="w-6 h-6 flex-shrink-0 text-brand-purple block relative top-1"
                    />
                    As quick and easy to use as any managed hosting provider
                    when you start
                  </li>
                  <li className="flex items-start justify-start gap-3 mt-2 relative">
                    <Icon
                      icon="check"
                      className="w-6 h-6 flex-shrink-0 text-brand-purple block relative top-1"
                    />
                    As powerful and flexible as building on AWS or best-in-class
                    CDNs when you are ready to scale
                  </li>
                  <li className="flex items-start justify-start gap-3 mt-2 relative">
                    <Icon
                      icon="check"
                      className="w-6 h-6 flex-shrink-0 text-brand-purple block relative top-1"
                    />
                    Intelligence as a first-class primitive, not a complicated
                    or expensive add-on
                  </li>
                  <li className="flex items-start justify-start gap-3 mt-2 relative">
                    <Icon
                      icon="check"
                      className="w-6 h-6 flex-shrink-0 text-brand-purple block relative top-1"
                    />
                    Even more affordable than rolling-your-own. No surprise
                    charges or nasty jumps as you scale.
                  </li>
                </ul>
              </p>
              <p>
                We've recruited engineers to help us with previous experience at
                Hulu, the BBC, Netflix, and other global-scale organisations.
                We're not afraid to get our hands dirty and work with our own
                servers or networking stack. We've also got some novel ideas
                behind-the-scenes that let us run things super economically as
                we scale.
              </p>
              <p>
                Most importantly, we know <em>*exactly*</em> what it's like to
                be a developer working on a big new media product.
              </p>
              <p>
                We're building the service we wish had existed when we were in
                those shoes.
              </p>
              <p>
                Please{' '}
                <a
                  href="/register"
                  className="underline hover:text-brand-magenta hover:no-underline">
                  try it out
                </a>{' '}
                and{' '}
                <a
                  href="/support"
                  className="underline hover:text-brand-magenta hover:no-underline">
                  get in touch
                </a>{' '}
                with any questions or feedback.
              </p>
              <p>
                <em>Paul, Paul, and the ittybit team</em>
              </p>
            </div>
          </div>
        </section>

        <section className="w-full max-w-screen-2xl mx-auto fade-in">
          <CallToAction />
        </section>

        <section className="fade-in">
          <div className="max-w-screen-xl mx-auto px-4 md:px-8 lg:px-12 3xl:px-0">
            <Testimonial
              text="Our PE lessons are used by 80,000 kids around the globe. Ittybit helps us serve HD video reliably and affordably to any region, and that means kids can train wherever they live."
              name="Simon Brundish"
              title="Founder, Strength:Lab"
              avatar="https://ittybit-app.ittybitcdn.com/img/simon.jpg"
              logo="https://ittybit-app.ittybitcdn.com/img/strengthlab-logo-white@2x.png"
              image="https://ittybit-app.ittybitcdn.com/med_49im8p54YLdPzCC1/sl-image.png"
            />
          </div>
        </section>
      </div>
    </div>
  );
}
